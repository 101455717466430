.pieChartStyle {
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  background: white;
}

.containerProcessosStyle {
  padding: 20px;
  min-height: 100%;
  padding-bottom: 0px;
}

.linkProcessoStyle {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  background: white;
  padding: 10px;
  margin-top: 15px;
}

.headerPainelStyle {
  font-size: 16px;
  color: #000000de;
}

.gridStyled {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}
