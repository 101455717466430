.root {
  display: 'flex';
}

.spacer {
  flex-grow: 1;
}

.content {

  flex-grow: 1;
  padding-top: 80px;
  padding-right: 25px;
  padding-left: 25px;
}

.paper {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
}

.closeMenuButton {
  margin-left: 75% !important;
  margin-top: 7px !important;
  width: 50px;
}

.avatarLoadingCircle {
  height: 30px !important;
  width: 30px !important;
}

/* persistentDrawer */


.drawerPaper {
  width: auto;
  z-index: 1 !important;
  position: fixed !important;
  background: #192a40 !important;
}


.drawerContainer {
  overflow: 'auto';
}

.content {
  flex-grow: 1;
}

.contentPadding {
  margin-left: 8%;
}
.contentPadding20 {
  margin-left: 20%;
}



.PrivateValueLabel-circle-16 {
  background: transparent !important;
}

.MuiLinearProgress-barColorPrimary {
  background-color: #38f205 !important;
}

.MuiLinearProgress-colorPrimary {
  background-color: rgb(36,152,1,27%) !important;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}