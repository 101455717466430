@font-face {
  font-family: Avenir;
  src: url('../../../core/fonts/AvenirLTStd-Roman.otf');
}

.root {
  display: 'flex';
}

h1,h2,h3,h4,h5,h6,p, span,button{
  font-family: Avenir !important;
}


.spacer {
  flex-grow: 1;
}

.content {
  flex-grow: 1;
  padding-top: 80px;
  padding-right: 25px;
  padding-left: 25px;
}

.paper {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
}

.closeMenuButton {
  margin-left: 75% !important;
  margin-top: 7px !important;
  width: 50px;
}

.avatarLoadingCircle {
  height: 30px !important;
  width: 30px !important;
}

.MuiFormLabel-root.Mui-focused {
  color: #192a40!important
}

.MuiDialog-paperFullWidth {
  width: 80% !important;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: #38f205 !important;
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #39f302 !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #39f302 !important;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}