.tittlePage {
  padding: 20px;
  transition: 2s;
}

.containerBox {
  background: linear-gradient(#435895 0%, #192a40 100%);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 100px 100px;
}

.buttonFullStyle {
  width: 100%;
  padding: 10px 50px !important;
  justify-content: space-between !important;
  text-transform: none !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  background-color: #ffffff !important;
  color: #111c39 !important;
  margin-top: 20px !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 5px;
  text-decoration: none;
}

.subtittleStyle {
  color: #8e94a1;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}