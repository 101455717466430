.root {
  height: 100vh;
}

.image {
  background-image: url('../../../res/images/login_background_opacity.png');
  background-repeat: no-repeat;
  background-color: #192a40 !important;
  background-size: cover;
  background-position: center;
}

.paperWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loginPaper {
  /* margin: ; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* .avatar { */
  /* margin: ; */
  /* background-color: ; */
/* } */

.form {
  width: 100%; 
  /* Fix IE 11 issue. */
  /* margin-top: ; */
}

/* .submit { */
  /* margin: ; */
/* } */

/* Gambiarra pra acertar o component do firebase */

.firebaseui-idp-button {
  max-width: none !important;
  height: 56px !important;
}

.firebaseui-idp-password,
.firebaseui-idp-password:hover,
.mdl-button.firebaseui-idp-password:active,
.mdl-button.firebaseui-idp-password:focus {
  background-color: #38f205 !important;
}

.mdl-button--raised.mdl-button--colored {
  background: #38f205 !important;
  color: #192a40 !important;
}

.firebaseui-id-secondary-link::after {
  content: 'cancelar';
  visibility: visible;
  font-size: 14px;
  color: #192a40 !important;
}

.firebaseui-idp-favicon, .firebaseui-idp-icon {
  border: none;
  display: inline-block;
  vertical-align: middle;
  color: #192a40 !important;
}

.firebaseui-idp-text-long {
  visibility: hidden !important;
  font-size: 0px !important;
}

.firebaseui-idp-google > .firebaseui-idp-text-long::after {
  content: 'Acesse com sua conta Google'; 
  visibility: visible;
  font-size: 16px;
}

.firebaseui-idp-password > .firebaseui-idp-text-long::after {
  content: 'Acesse com o seu Email'; 
  visibility: visible;
  font-size: 16px;
  color: #192a40 !important;
}

.firebaseui-id-secondary-link {
  visibility: hidden !important;
  font-size: 0px !important;
}

.firebaseui-id-secondary-link::after {
  content: 'cancelar'; 
  visibility: visible;
  font-size: 16px;
}

.firebaseui-id-secondary-link {
  visibility: hidden !important;
  font-size: 0px !important;
}

.firebaseui-id-secondary-link::after {
  content: 'cancelar'; 
  visibility: visible;
  font-size: 14px;
}

.firebaseui-id-submit {
  /* visibility: hidden !important; */
  font-size: 0px !important;
}

.firebaseui-id-submit::after {
  content: 'próximo'; 
  font-size: 14px;
}

.firebaseui-title {
  /* visibility: hidden !important; */
  font-size: 0px !important;
}

.firebaseui-title::after {
  content: 'Acessar por e-mail'; 
  font-size: 18px;
}

label[for="ui-sign-in-email-input"] {
  /* visibility: hidden !important; */
  font-size: 0px !important;
}

label[for="ui-sign-in-email-input"]::after {
  visibility: visible;
  content: "E-mail";
  left: 0;
  width: 100%;
  font-size: 16px;
  padding-top: 2px;
}

label[for="ui-sign-in-name-input"] {
  /* visibility: hidden !important; */
  font-size: 0px !important;
}

label[for="ui-sign-in-name-input"]::after {
  visibility: visible;
  content: "Nome e Sobrenome";
  left: 0;
  width: 100%;
  font-size: 16px;
  padding-top: 2px;
}

label[for="ui-sign-in-new-password-input"] {
  /* visibility: hidden !important; */
  font-size: 0px !important;
}

label[for="ui-sign-in-new-password-input"]::after {
  visibility: visible;
  content: "Nova Senha";
  left: 0;
  width: 100%;
  font-size: 16px;
  padding-top: 2px;
}

.firebaseui-subtitle {
  visibility: hidden !important;
  font-size: 0px !important;
}

.firebaseui-subtitle::after {
  content: 'Você já possui uma conta'; 
  visibility: visible;
  font-size: 22px;
  left: 0;
}

.firebaseui-text {
  visibility: hidden !important;
  font-size: 0px !important;
}

.firebaseui-text::after {
  content: 'Você já possui uma conta com este email, utilize o metodo adequado para acessar ela.'; 
  visibility: visible;
  font-size: 16px;
}
