#labelhover {
  position: relative;
  margin-bottom: 60%;
  left: 40%;
  opacity: 0;
}

#hoverclone:hover #labelhover {
  animation: movelabel 0.5s forwards;
}
.closeoptionAreas {
  display: none;
}
.openOptionAreas {
  animation: openAreasButton 1s forwards;
}

.buttonCloneProcess {

  border-color: #a0a0a0;
  color: white;
  width: 100%;
  height: 56px;
  cursor: pointer;
}

.buttonCloneProcessDisable{
  border-color: #a0a0a0;
  color: white;
  width: 100%;
  height: 56px;
  opacity: 0.5;
  cursor: none;
}

@keyframes openAreasButton {
  from {
    display: none;
    height: 0%;
  }

  to {
    display: block;
    height: 30%;
  }
}

@keyframes movelabel {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
